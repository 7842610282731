import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ReactGA from 'react-ga';

class NotFoundPage extends React.Component {

  componentDidMount(){
    ReactGA.initialize('UA-115464417-1');
    if(!(window.location.hostname === 'localhost')){
      ReactGA.pageview(window.location.pathname + window.location.search);      
    }
  }

  render(){
    return(
      <Layout>
        <SEO title="404: Not found" />
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Layout>  
    )
  }
}

export default NotFoundPage
